const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://1syxhxmcrh.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://4bvxssb0je.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'develop',
    HOSTNAME: 'https://company.dev.pluto.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.dev.pluto.forwoodsafety.com',
    WEBSOCKET: 'wss://hhepu5o7r3.execute-api.us-west-2.amazonaws.com/dev'
  },
};

export default config;